import React, { useCallback, useEffect, useMemo } from 'react';
import routes from './routes';
import Private from './utils/Private';
import { useSelector } from 'react-redux';
import AppAction from './store/actions/AppAction';
import { Switch, Route, useHistory } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { RootState, useThunkDispatch } from "./store/store";

// const registerServiceWorker = async () => {
//     if ('serviceWorker' in navigator && import.meta.env.EXPOSED_NODE_ENV == 'production') {
//         try {
//             const registration = await navigator.serviceWorker.register('sw.js');
//             console.log('Hooray. Registration successful, scope is:', registration.scope);
//         } catch (error) {
//             console.log('Whoops. Service worker registration failed, error:', error);
//         }
//     }
// }

const mapRootState = (state: RootState) => {
    return { token: state.appState.token };
}

const App = () => {
    const { token } = useSelector(mapRootState);
    const dispatch = useThunkDispatch();
    const history = useHistory();

    const ping = useCallback(() => token ? dispatch(AppAction.ping({})) : undefined, [dispatch, token]);

    useEffect(() => {
        // registerServiceWorker();
        const unregisterHistory = history.listen((location) => document.body.scrollTo(0, 0));
        return () => { unregisterHistory(); }
    }, []);

    useEffect(() => {
        ping();
        const intervalId = setInterval(ping, 60 * 1000);
        return () => { clearInterval(intervalId); };
    }, [ping]);

    const renderedRoutes = useMemo(() => routes.map(route => {
        const component = route.isPrivate ? Private(route.component) : route.component;
        return <Route key={route.name} path={route.path} component={component} exact={route.exact} />
    }), []);

    return (
        <MantineProvider>
            <Switch>
                {renderedRoutes}
            </Switch>
        </MantineProvider>
    );
}

export default App;